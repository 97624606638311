module.exports = {
  sassVariables: {
    primary: '#0096e2',
    secondary: '#1e2128',
    'navbar-back-rotation': '230deg',
    'navbar-link-rotation': '-100deg',
    'login-background': "url('/images/c9img1.png')",
    'secondary-background': "''",
  },
  images: {
    loginLogo: '/images/c9-white.png',
    iconLogo: '/images/c9icon.png',
    fullLogo: '/images/cloud-9-seeklogo.svg',
    overviewLogo: '/images/cloud-9-icon.svg',
    faviconLogo: '/images/c9-favicon.png',
    poweredByAdvocate: '/images/powered-by-advocate.png',
  },
}
